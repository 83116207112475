import React from 'react';
import Navbar from "../components/App/YftelNavbar"
import Footer from "../components/App/YftelFooter"
import Layout from "../components/App/YftelLayout"
import MainBanner from '../components/YftelStartup/MainBanner'
import FeaturedServices from '../components/YftelStartup/FeaturedServices'
import WhyChooseUs from '../components/YftelStartup/WhyChooseUs'
import WhatWeDo from '../components/YftelStartup/WhatWeDo'
import Funfacts from '../components/YftelStartup/Funfacts'
import Services from '../components/YftelStartup/Services'
import Testimonials from '../components/YftelStartup/Testimonials'
import WeServe from '../components/YftelStartup/WeServe'
import TeamMembers from '../components/YftelStartup/TeamMembers'
import CaseStudy from '../components/YftelStartup/CaseStudy'
import BlogPost from '../components/YftelStartup/BlogPost'
import StartProject from '../components/Common/YftelStartProject';

const Home = () => {
    return (
        <Layout>
            <Navbar />
            <MainBanner />
            <Funfacts />
            <FeaturedServices />
            <StartProject />
            <WhyChooseUs />
            <Testimonials />
            <WhatWeDo />
            <Services />
	    <TeamMembers />
            <CaseStudy />
          {/* <BlogPost /> */}
            <Footer />
        </Layout>
    );
}

export default Home;
